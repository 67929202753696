import { CheckIcon } from 'lucide-react';

// An individual feature item usually shown in a list of available features.
// E.g. "Unlimited apps", "Unlimited team members", etc.
export function FeatureItem({ description }: { description: string }) {
  return (
    <li className="text-default mb-3.5 flex items-center gap-3 text-lg">
      <span className="inline-flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-green-200">
        <CheckIcon className="m-1 h-6 w-6 text-green-700" />
      </span>
      {description}
    </li>
  );
}
